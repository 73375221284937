<template>
    <div class="freight-content">
        <div class="freight-title"  v-if="freight.title">
            <span>{{freight.title}}</span>
            <div class="freight-btn">
                <el-link type="primary" :underline="false" @click="editFreight(freight.id)">修改</el-link>
                <el-link type="danger" :underline="false" @click="delFreight(freight.id)">删除</el-link>
            </div>
        </div>
        <!-- 有配送地区 -->
        <el-table :data="freight.freightList" class="customTable" style="width:100%;margin-top:20px;flex:1" height="1%">
            <el-table-column label="运送方式" prop="delivery_mode"></el-table-column>
            <el-table-column label="运送到（国内）" align="center" width="320">
                <template slot-scope="scope">
                    <span>{{scope.row.city?scope.row.city:'为划分的区域将归于该模板下的默认运费'}}</span>
                </template>
            </el-table-column>
            <el-table-column label="首件（个）" align="center">
                <template slot-scope="scope">
                    <span>
                          {{scope.row.city?scope.row.first_thing:freight.defaultNum}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="首费（元）" align="center">
                <template slot-scope="scope">
                    <span>
                        {{scope.row.city?scope.row.first_cost:freight.defaultPrice}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="续件（个）" align="center">
               <template slot-scope="scope">
                    <span>
                        {{scope.row.city?scope.row.add_thing:freight.addNum}}
                    </span>
                </template>
            </el-table-column>
           <el-table-column label="续费（元）" align="center">
                <template slot-scope="scope">
                    <span>
                        {{scope.row.city?scope.row.addPrice:freight.addPrice}}
                    </span>
                </template>
            </el-table-column>
            <template slot="empty">
                <div class="null-data">
                  <div style="display:flex;flex-direction:column;align-items:center;">
                    <img src="../../../../assets/images/student/freight-null-data.png">
                    <span>暂无商品</span>
                  </div>
                  <el-button type="primary" plain @click="addBtn()">添加运费单模板</el-button>
                </div>
            </template>
        </el-table>
    </div>
</template>

<script>
    import {stuOpStuOpFreightTemplate,stuOpStuFreightTemplateDel,} from '@/utils/apis'
    export default {
        name: "FreightTemplate",
        data(){
            return{
                examModuleId:null,
                // 运费单模板
                freight: {
                    id: '',
                    title: '',
                    defaultPrice: 0,
                    defaultNum: 0,
                    addNum: 0,
                    addPrice: 0,
                    exemptPostage: 0,
                    freightList: [],
                },
            }
        },

        mounted() {
            this.getStuOpStuOpFreightTemplate()
            if (this.$route.query.exam_module_id){
                this.examModuleId = Number(this.$route.query.exam_module_id)
            }
        },
        methods:{
            //添加按钮
            addBtn(){
                if (this.$route.query.id) {
                    this.$router.push({
                        path: '/student/trainCenter/onlineShopManagement/studentFreight',
                        query: {
                            id: this.$route.query.id,
                            exam_module_id:this.$route.query.exam_module_id,
                            course_id:this.$route.query.course_id,
                        }
                    });
                } else {
                    this.$router.push({
                        path: '/student/examing/onlineShopManagement/studentFreight',
                        query:{
                            exam_module_id:this.$route.query.exam_module_id
                        }
                    })
                }
            },
            // 运单模板列表
            getStuOpStuOpFreightTemplate(){
                let params = {}
                if (this.$route.query.id){
                    params.op_id=this.$route.query.id
                }
                if (this.$route.query.course_id){
                    params.course_id=this.$route.query.course_id
                }
                stuOpStuOpFreightTemplate(params).then((res) => {
                    if (res.data) {
                        this.freight.id = res.data.id;
                        this.freight.title = res.data.freight_template_title;
                        this.freight.defaultPrice = res.data.default_price;
                        this.freight.defaultNum = res.data.default_num;
                        this.freight.addPrice = res.data.add_price;
                        this.freight.addNum = res.data.add_num;
                        this.freight.freightList = res.data.freight_setting;
                        this.freight.exemptPostage = res.data.is_exempt_postage
                        if (res.data.is_exempt_postage === 1) {
                            //商家包邮
                            this.freight.freightList = [{
                                add_cost: 0,
                                add_thing: 1,
                                city: "全国",
                                delivery_mode: "快递",
                                first_cost: 0,
                                first_thing: 1,
                                addPrice:res.data.add_price
                            }]
                        } else {
                            let obj = {
                                add_cost: res.data.default_price,
                                add_thing: res.data.default_num,
                                city: "",
                                delivery_mode: "快递",
                                first_cost: res.data.default_price,
                                first_thing: res.data.default_num,
                            }
                            this.freight.freightList.unshift(obj)
                        }
                        console.log(this.freight.freightList)
                    }else {
                        this.freight = this.$options.data().freight
                    }
                })
            },
            // 运费模板修改
            editFreight(id){
                if(this.$route.query.id){
                    this.$router.push({
                        path: '/student/trainCenter/onlineShopManagement/studentFreight',
                        query: {
                            t_id: id,
                            id:this.$route.query.id,
                            exam_module_id:this.examModuleId,
                            course_id:this.$route.query.course_id,
                        }
                    })
                }else {
                    this.$router.push({
                        path: '/student/examing/onlineShopManagement/studentFreight',
                        query: {
                            t_id: id,
                            exam_module_id:this.examModuleId,
                        }
                    })
                }
            },
            // 运费模板删除
            delFreight(id){
                this.$confirm(`是否删除该模板，删除将无法恢复`, '删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning'
                }).then(() => {
                    let params = {
                        id:id
                    }
                    stuOpStuFreightTemplateDel(params).then((res) => {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        })
                        this.getStuOpStuOpFreightTemplate()
                    }).catch((err) => {
                        console.log('err', err)
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .freight-content{
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 20px;
        .freight-title {
            font-size: 20px;
            padding-left: 15px;
            line-height: 1;
            margin-top: 20px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 5px;
                height: 20px;
                background: #2DC079;
                border-radius: 3px;
                transform: translateY(-50%);
            }
        }
    }

</style>